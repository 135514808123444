import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import dehImage from '../images/logdeh.png';
import projectData from '../data/projectData';
import MyceliumBackground from '../components/MyceliumBackground';

const ContentPreview = ({ content }) => {
  if (!content.type || content.type === 'image') {
    return (
      <div className="relative w-full h-48">
        <img 
          src={content.url} 
          alt={content.alt || 'Project preview'}
          className="absolute w-full h-full object-contain"
        />
      </div>
    );
  }

  if (content.type === 'website') {
    return (
      <div className="w-full h-48 overflow-hidden relative">
        <iframe
          src={content.url}
          title={content.alt || 'Website preview'}
          className="w-[1200px] h-[800px] border-0 absolute top-0 left-0"
          style={{
            transform: 'scale(0.3)',
            transformOrigin: 'top left',
          }}
          loading="lazy"
          allow="microphone; camera"
          sandbox="allow-scripts allow-same-origin allow-forms"
        />
      </div>
    );
  }

  return null;
};

const ToggleFilter = ({ isDigital, onToggle }) => (
  <div className="flex justify-center items-center space-x-4">
    <button
      onClick={() => onToggle(true)}
      className={`px-4 py-1 text-sm rounded-full transition-all duration-300 ${
        isDigital
          ? 'bg-gray-200 text-gray-800'
          : 'text-gray-500 hover:bg-gray-100'
      }`}
    >
      Digital
    </button>
    <div className="h-4 w-px bg-gray-300"></div>
    <button
      onClick={() => onToggle(false)}
      className={`px-4 py-1 text-sm rounded-full transition-all duration-300 ${
        !isDigital
          ? 'bg-gray-200 text-gray-800'
          : 'text-gray-500 hover:bg-gray-100'
      }`}
    >
      Analog
    </button>
  </div>
);

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const ProjectCard = ({ project, selectedCategory, index }) => {
  return (
    <Link 
      to={project.link}
      state={{ selectedCategory }}
      className="group relative bg-white rounded-lg overflow-hidden hover:shadow-lg transition-all duration-300 opacity-0 animate-fadeIn"
      style={{
        animationDelay: `${index * 100}ms`,
        animationFillMode: 'forwards'
      }}
    >
      <ContentPreview content={project.preview} />
      <div className="p-6">
        <span className="absolute top-4 right-4 text-sm text-gray-400 bg-white/80 px-2 py-1 rounded">
          {project.category}
        </span>
        <div className="mb-2 flex justify-between items-start">
          <h2 className="text-xl font-semibold">{project.title}</h2>
          <span className="text-sm text-gray-500">{project.year}</span>
        </div>
        <p className="text-gray-600">{project.description}</p>
      </div>
    </Link>
  );
};

const Home = () => {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [isDigital, setIsDigital] = useState(true);
  const [isGridVisible, setIsGridVisible] = useState(false);
  
  useEffect(() => {
    setIsGridVisible(true);
  }, []);

  useEffect(() => {
    if (location.state?.fromItemPage) {
      const category = location.state.selectedCategory?.toLowerCase();
      if (category === 'digital') {
        setIsDigital(true);
        setSelectedCategory('all');
      } else {
        setIsDigital(false);
        setSelectedCategory(category || 'all');
      }
    }
  }, [location.state]);

  const handleToggle = (digital) => {
    setIsGridVisible(false);
    setTimeout(() => {
      setIsDigital(digital);
      setSelectedCategory('all');
      setIsGridVisible(true);
    }, 300);
  };

  const projects = Object.entries(projectData).map(([id, project]) => ({
    id,
    title: project.title,
    description: project.shortDescription,
    category: project.category,
    year: project.year,
    link: project.link,
    preview: project.preview,
    isDigital: project.category === 'Digital'
  }));

  const filteredProjects = projects.filter(project => {
    if (isDigital) {
      return project.isDigital;
    }
    return !project.isDigital && (
      selectedCategory === 'all' || 
      project.category.toLowerCase() === selectedCategory.toLowerCase()
    );
  });

  const shuffledProjects = shuffleArray(filteredProjects);

  const handleCategoryChange = (category) => {
    setIsGridVisible(false);
    setTimeout(() => {
      setSelectedCategory(category.toLowerCase());
      setIsGridVisible(true);
    }, 300);
  };

  return (
    <div className="min-h-screen p-8 relative z-10">
      <MyceliumBackground />
      
      <div className="max-w-6xl mx-auto mb-16 text-center animate-fadeSlideDown">
        <div className="w-32 h-32 mx-auto mb-8">
          <img src={dehImage} alt="DEH" />
          <h1 className="text-xs text-gray-500 tracking-tight mb-8 w-full text-center">
            Daniel Eigler-Harding
          </h1>
        </div>
        
        <div className="mb-4">
          <p className="text-xs text-gray-500 mb-4">
            Pursuing harmonious creativity through diverse mediums and an array of tools. 
          </p>
        </div>

        <div className="mb-16">
          <ToggleFilter isDigital={isDigital} onToggle={handleToggle} />
        </div>
      </div>

      <div className="max-w-6xl mx-auto mb-8">
        <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 transition-opacity duration-300 ${isGridVisible ? 'opacity-100' : 'opacity-0'}`}>
          {shuffledProjects.map((project, index) => (
            <ProjectCard
              key={project.id}
              project={project}
              selectedCategory={selectedCategory}
              index={index}
            />
          ))}
        </div>
      </div>

      {!isDigital && (
        <div className="max-w-6xl mx-auto flex justify-center space-x-4">
          {['All', 'Systems', 'Precision', 'Dynamic'].map(category => (
            <button
              key={category}
              onClick={() => handleCategoryChange(category)}
              className={`px-4 py-2 rounded-full text-sm transition-all duration-300 ${
                selectedCategory === category.toLowerCase()
                  ? 'bg-gray-200 text-gray-800' 
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              {category}
            </button>
          ))}
        </div>
      )}

      <div className="fixed bottom-0 left-0 right-0 mb-4 text-center">
        <div className="flex justify-center space-x-4 text-xs text-gray-500">
          <a href="mailto:danieleiglerharding@gmail.com" className="hover:text-blue-600 transition-colors">
            Email
          </a>
          <span>•</span>
          <a href="https://www.linkedin.com/in/daniel-eigler-harding-5301922a8/" className="hover:text-blue-600 transition-colors">
            LinkedIn
          </a>
        </div>
      </div>

      <style>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(20px) scale(0.95);
          }
          to {
            opacity: 1;
            transform: translateY(0) scale(1);
          }
        }

        @keyframes fadeSlideDown {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fadeIn {
          animation: fadeIn 0.6s ease-out;
        }

        .animate-fadeSlideDown {
          animation: fadeSlideDown 0.8s ease-out;
        }
      `}</style>
    </div>
  );
};

export default Home;